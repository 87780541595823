import { useCallback, useMemo, useState } from "react";
import { CallBackProps, default as Joyride } from "react-joyride";
import { default as Modal } from "react-modal";
import {
  BACKGROUND_COLOR,
  COLOR_1,
  DARKER_PRIMARY,
  PRIMARY_COLOR,
  PRIMARY_COLOR_FADED,
  TEXT_COLOR,
} from "../data/colors";
import { Button } from "./Button";

export function NuxModal({
  isModalOpen,
  setIsModalOpen,
}: {
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
}) {
  const [run, setRun] = useState(false); // Controls whether the tour runs

  const steps = [
    {
      disableBeacon: true,
      target: ".joyride-piano",
      content: (
        <p>
          Use keys <b>A-Z</b> on your <b>computer keyboard</b> to play the
          piano.
          <br />
          <br />
          Plug in your <b>MIDI keyboard</b> for the full experience!
        </p>
      ),
      styles: {
        options: {
          backgroundColor: PRIMARY_COLOR,
          textColor: "#fff",
        },
      },
    },
    {
      disableBeacon: true,
      target: ".joyride-new-chords",
      content: "Change harmony.",
      styles: {
        options: {
          backgroundColor: "#f04",
          textColor: "#fff",
        },
      },
    },
    {
      disableBeacon: true,
      target: ".joyride-slider",
      content: "Explore similar harmonies.",
      styles: {
        options: {
          backgroundColor: "#f04",
          textColor: "#fff",
        },
      },
    },
    {
      disableBeacon: true,
      target: ".joyride-download-chords",
      content: (
        <p>
          Download your chords as MIDI - drag n drop into your DAW.
          <br />
          <br />
          Enjoy unlimited free downloads!
        </p>
      ),
      styles: {
        options: {
          backgroundColor: "#f04",
          textColor: "#fff",
        },
      },
    },
    {
      disableBeacon: true,
      target: ".joyride-help-button",
      content: "Click here anytime to watch this tutorial again.",
      styles: {
        options: {
          backgroundColor: "#f04",
          textColor: "#fff",
        },
      },
    },
  ];

  const onClose = useCallback(() => {
    setIsModalOpen(false);
    setRun(true);
    setHasSeenNux();
  }, [setIsModalOpen]);

  const [overrideStepIndex, setOverrideStepIndex] = useState<
    number | undefined
  >(undefined);

  const handleJoyrideCallback = useCallback(
    (data: CallBackProps) => {
      const { status, action } = data;
      const finishedStatuses = ["finished", "skipped"];

      if (finishedStatuses.includes(status)) {
        setRun(false); // Stops the tutorial
        setOverrideStepIndex(undefined);
      } else if (action === "close") {
        setOverrideStepIndex(steps.length - 1);
      }
    },
    [steps.length]
  );

  return (
    <>
      <Joyride
        // beaconComponent={<div></div>}
        callback={handleJoyrideCallback}
        stepIndex={overrideStepIndex}
        steps={steps}
        run={run} // Run the tour
        continuous={true} // Automatically go to the next step
        scrollToFirstStep={false} // Scroll to elements if they are not visible
        showProgress={true} // Show a progress bar
        // showSkipButton={true} // Show a skip button
        locale={{ last: "Let's go!" }}
        disableOverlayClose
        styles={{
          buttonBack: {
            color: TEXT_COLOR,
            fontFamily: "Varela Round",
            opacity: 0.7,
          },
          buttonClose: {
            color: TEXT_COLOR,
            opacity: 0.4,
            fontFamily: "Varela Round",
          },
          buttonNext: {
            color: TEXT_COLOR,
            fontFamily: "Varela Round",
          },
          buttonSkip: {
            color: TEXT_COLOR,
            fontFamily: "Varela Round",
            opacity: 0.4,
          },
          spotlight: {
            borderRadius: 16,
          },
          overlay: {
            backgroundColor: "rgba(0,0,0,0.6)",
          },
          tooltip: {
            backgroundColor: BACKGROUND_COLOR,
            color: TEXT_COLOR,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            borderRadius: 16,
            borderWidth: 3,
            border: `3px solid ${PRIMARY_COLOR_FADED}`,
            fontFamily: "Varela Round",
          },
          options: {
            arrowColor: PRIMARY_COLOR_FADED,
            overlayColor: "rgba(79, 26, 0, 0.4)",
            primaryColor: "#000",
            backgroundColor: BACKGROUND_COLOR,
            zIndex: 1000,
          },
        }}
      />
      <Modal
        isOpen={isModalOpen}
        onRequestClose={onClose}
        contentLabel="Login Modal"
        style={{
          overlay: {
            backgroundColor: "rgba(0,0,0,0.75)",
          },
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: BACKGROUND_COLOR,
            color: TEXT_COLOR,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            borderRadius: 16,
            gap: 16,
            padding: 40,
            borderWidth: 3,
            border: `3px solid ${PRIMARY_COLOR_FADED}`,
            fontFamily: "Varela Round",
          },
        }}
        // overlayClassName="overlay"
      >
        <h2>
          Welcome to <b>Harmonia!</b>
        </h2>
        <p>
          The <b>music playground</b> for people of all skill levels.
          <br />
          <br />
          Yes, that includes <b>you!</b>
        </p>
        <div>
          <Button onClick={onClose} text={"Take a quick tour"}></Button>
        </div>
      </Modal>
    </>
  );
}

export function getHasSeenNux() {
  return localStorage.getItem("has_seen_nux");
}

function setHasSeenNux() {
  return localStorage.setItem("has_seen_nux", "true");
}
