import { useCallback, useRef, useState } from "react";
import { ActiveHarmony, NoteWithOctave } from "../types";

export function useActiveHarmony() {
  const [activeHarmonyState, setActiveHarmonyImpl] = useState<ActiveHarmony>({
    activeHarmonyNotes: [],
    scaleNotes: ["D", "F", "G", "A", "Bb", "C"],

    lastScaleNoteMap: {},
    scaleNoteMap: {
      65: { name: "D", octave: 3 },
      66: { name: "A", octave: 2 },
      67: { name: "F", octave: 2 },
      68: { name: "G", octave: 3 },
      69: { name: "C", octave: 5 },
      70: { name: "A", octave: 3 },
      71: { name: "Bb", octave: 3 },
      72: { name: "C", octave: 4 },
      73: { name: "Bb", octave: 5 },
      74: { name: "D", octave: 4 },
      75: { name: "F", octave: 4 },
      76: { name: "G", octave: 4 },
      77: { name: "C", octave: 3 },
      78: { name: "Bb", octave: 2 },
      79: { name: "C", octave: 6 },
      80: { name: "D", octave: 6 },
      81: { name: "A", octave: 4 },
      82: { name: "D", octave: 5 },
      83: { name: "F", octave: 3 },
      84: { name: "F", octave: 5 },
      85: { name: "A", octave: 5 },
      86: { name: "G", octave: 2 },
      87: { name: "Bb", octave: 4 },
      88: { name: "D", octave: 2 },
      89: { name: "G", octave: 5 },
      90: { name: "C", octave: 2 },
    },
    lastScaleNoteMapForMIDIInput: {},
    scaleNoteMapForMIDIInput: {},
  });

  const activeHarmonyRef = useRef<ActiveHarmony>(activeHarmonyState);

  const setActiveHarmony = useCallback((activeHarmony: ActiveHarmony) => {
    // console.log(activeHarmony);
    activeHarmonyRef.current = activeHarmony;
    setActiveHarmonyImpl(activeHarmony);
  }, []);

  return { activeHarmonyRef, activeHarmonyState, setActiveHarmony };
}
