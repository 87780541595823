import { useCallback, useRef, useState } from "react";
import { NoteWithOctave } from "../types";

export function usePressedNotes() {
  const [pressedNotesState, setPressedNotesImpl] = useState<NoteWithOctave[]>(
    []
  );

  const pressedNotesRef = useRef<NoteWithOctave[]>([]);

  const setPressedNotes = useCallback((pressedNotes: NoteWithOctave[]) => {
    pressedNotesRef.current = pressedNotes;
    setPressedNotesImpl(pressedNotes);
  }, []);

  return { pressedNotesRef, pressedNotesState, setPressedNotes };
}
