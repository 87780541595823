import { useCallback } from "react";
import { Analytics, logEvent as logFirebaseEvent } from "firebase/analytics";

const isDev = process.env.NODE_ENV === "development";

const myUserId = "feeb9059-7185-498f-b6f7-d208a55098fd";

export function useLogger({
  analytics,
  userId,
}: {
  analytics: Analytics;
  userId: string;
}) {
  const logEvent = useCallback(
    ({
      eventName,
      details,
    }: {
      eventName: string;
      details?: Record<string, string>;
    }) => {
      if (!isDev && userId !== myUserId) {
        // console.log("will log");
        logFirebaseEvent(analytics, eventName, { ...details, userId });
      } else {
        // console.log("no log");
      }
    },
    [analytics, userId]
  );

  return { logEvent };
}
