import { LuDrum } from "react-icons/lu";
import React, { useCallback, useEffect, useRef, useState } from "react";
// NOTE: consider changing to ToneJS midi file writer if this gets annoying
// https://github.com/Tonejs/Midi/blob/master/README.md
import "rc-slider/assets/index.css";
import "react-dropdown/style.css";
import "./DropDown.css";
import "./icons.css";
import * as Tone from "tone";
import SampleJSON from "../data/sample.json";
import { Header } from "./Header";
import {
  ActiveHarmony,
  Chord,
  ClosedOrOpen,
  AlternateChordSetType,
  HarmonyNote,
  HarmonyState,
  LoopSettings,
  LoopState,
  MidiScaleNoteMap,
  Note,
  NoteName,
  noteOctaveStr,
  NoteWithOctave,
  noteWithOctaveFromStr,
  sameNoteWithOctave,
  ScaleNoteMap,
  AlternateChordType,
} from "../types";

import { NOTE_ARR } from "../data/theory";
import {
  getTriggerKey,
  KEY_NAME_TO_NUMBER_MAP,
  KEY_NOTES_A_Z,
  KEY_NUMBER_TO_NOTE_MAP,
  noteHasDifferentTriggerKey,
} from "../data/keyboard";
import {
  addAlternateChord,
  removeAlternateChords,
  trimToSix,
} from "../utils/composition";
import { UserInputChord } from "./NewChords";
import { refreshChordsMIDITrack } from "../utils/midiOutput";
import { useTimeLoop } from "./useTimeLoop";
import { DRUM_NOTE } from "../utils/synths";
import {
  getEnharmonicFlat,
  getFirstNoteAboveC,
  getOffset,
} from "../utils/theory";
import { randomChoice, shuffleArray } from "../utils/arrays";
import { PlayPauseButton } from "./PlayPauseButton";
import { ChordStuff } from "./ChordStuff";
import { VolumeSetting } from "./VolumeSetting";
import { LeadStuff, STARTING_OCTAVE } from "./LeadStuff";
import { getHasSeenNux, NuxModal } from "./NuxModal";
import {
  ACTIVE_COLOR,
  BACKGROUND_COLOR,
  PRIMARY_COLOR,
  TEXT_COLOR,
} from "../data/colors";
import { randomizeLowLevelSettings } from "../utils/settings";
import { usePressedNotes } from "../utils/usePressedNotes";
import { useActiveHarmony } from "../utils/useActiveHarmony";

const OFFLINE_MODE = false;

let hp_filter = new Tone.Filter(2000, "highpass", -96).toDestination();
let lp_filter_pad = new Tone.Filter(2200, "lowpass", -24).toDestination();
let lp_filter_lead = new Tone.Filter(4000, "lowpass", -12).toDestination();

function getNewPadSynth() {
  const urls = [
    {
      C4: "C4.m4a",
    },
    // {
    //   C6: "C40.wav",
    //   C4: "C30.wav",
    // },
    // { C5: "C42.wav" },
    // { Ab3: "G#3.wav" },
  ];

  const url = randomChoice(urls) as any;

  // Create a compressor with settings tailored for better low-end balance
  const compressor = new Tone.Compressor({
    threshold: -20, // Moderate threshold
    ratio: 4, // Gentle compression ratio
    attack: 0.05, // Slightly slower attack to preserve transients
    release: 0.3, // Balanced release time
  }).toDestination();

  // Create a low-pass filter with a higher cutoff to reduce harshness
  const lp_filter_pad = new Tone.Filter(1200, "lowpass").connect(compressor);

  // Create a high-pass filter to gently remove sub-bass rumble
  const hp_filter = new Tone.Filter(100, "highpass").connect(lp_filter_pad);

  // Add a refined EQ to balance lows and highs
  const eq = new Tone.EQ3({
    low: +3, // Boost lows to make them more present
    mid: -5, // Slight cut to mids to reduce muddiness
    high: -3, // Slight cut to highs to reduce distortion
  }).connect(hp_filter);

  // Create the sampler
  const synth = new Tone.Sampler({
    urls: url,
    baseUrl: process.env.PUBLIC_URL + "/assets/bass-synth/",
  }).connect(eq);

  synth.volume.value = -14; // Adjust volume as needed

  // Optional: Add very subtle distortion for a bit of grit
  const distortion = new Tone.Distortion({
    distortion: 0.05, // Very light distortion
    wet: 0.25, // Minimal blend to avoid overpowering highs
  }).connect(compressor);

  synth.connect(distortion);

  return synth;
}

const drumUrls = [
  "1.wav",
  "2.wav",
  "3.wav",
  "4.wav",
  "6-half.wav",
  "7-half.wav",
  "8.wav",
  "9.wav",
  "10-half.wav",
  "11.wav",
];
shuffleArray(drumUrls);
let drumUrlIdx = 0;

function getNextDrumUrl() {
  const nextDrumUrl = drumUrls[drumUrlIdx];
  console.log(nextDrumUrl);
  drumUrlIdx = (drumUrlIdx + 1) % drumUrls.length;
  return nextDrumUrl;
}

function getNewDrums(): {
  synth: Tone.Sampler;
  drumQs: number[];
} {
  const url = getNextDrumUrl();
  // console.log("drum url:", url);

  const drumQs = url.includes("half") ? [0, 8] : [0];

  const synth = new Tone.Sampler({
    urls: {
      [DRUM_NOTE]: url,
    },
    baseUrl: process.env.PUBLIC_URL + "/assets/drums/",
  }).toDestination();
  synth.volume.value = -4;
  return { synth, drumQs };
}

let padSynth = getNewPadSynth();

//padSynth.connect(hp_filter);

const bassSynth = getNewPadSynth();

let { synth: drumsSampler, drumQs: initDrumQs } = getNewDrums();

const leadSynth = getNewPadSynth();
// new Tone.Sampler({
//   urls: {
//     C5: "C4.wav",
//   },
//   baseUrl: process.env.PUBLIC_URL + "/assets/bass-synth/",
// }).toDestination();
// leadSynth.volume.value = 6;
// leadSynth.connect(hp_filter);

export function Harmonia({
  logEvent,
}: {
  logEvent: (event: {
    eventName: string;
    details?: Record<string, string>;
  }) => void;
}) {
  useEffect(() => {
    // console.log("render");
  });

  const [settings, setSettings] = useState<LoopSettings>({
    numberOfBars: "4",
    voicingComplexity: 2,
    closedOrOpen: "closed",
    passingChordFrequency: 0,

    majorTwoFive: false,
    minorTwoFive: false,
    diminished: false,
    modalInterchange: false,
    primaryDominant: true,
    secondaryDominant: false,
    substituteDominant: false,
  });

  const numberOfBarsInt = Number(settings.numberOfBars);

  const [harmonyState, setHarmonyStateImpl] = useState<HarmonyState>({
    key: "",

    bassNotes: [],
    harmonyNotes: [],

    chords: [],
    dependentChords: {
      // major two five, substitue dominant, diminished
      minorTwoFive: [],
      modalInterchange: [],
      secondaryDominant: [],
    },
    baseChords: [],

    resolvesToIds: [],
  });

  const { pressedNotesRef, pressedNotesState, setPressedNotes } =
    usePressedNotes();

  const { activeHarmonyRef, activeHarmonyState, setActiveHarmony } =
    useActiveHarmony();

  const [userInputChords, setUserInputChords] = useState<UserInputChord[]>(
    [1, 2, 3, 4].map((measureNumber) => {
      return {
        measureNumber,
        root: "-",
        quality: "-",
        active: false,
      };
    })
  );

  const [loopState, setLoopState] = useState<LoopState>({
    isPlaying: false,
    chordOnIdx: -1,
  });

  const setHarmonyState = useCallback(
    (newHarmonyState: HarmonyState) => {
      refreshChordsMIDITrack({
        barsInLoop: numberOfBarsInt,
        setChordsMidiFilePath,
        bassNotes: newHarmonyState.bassNotes,
        harmonyNotes: newHarmonyState.harmonyNotes,
      });
      setHarmonyStateImpl(newHarmonyState);
    },
    [numberOfBarsInt]
  );

  const applySettingsToHarmonyChords = useCallback(
    ({
      harmonyState,
      newSettings,
    }: {
      harmonyState: HarmonyState;
      newSettings: LoopSettings;
    }) => {
      // console.log(harmonyState);
      const chordTypes: AlternateChordSetType[] = [
        "minorTwoFive",
        "modalInterchange",
        "secondaryDominant",
      ];

      for (let i = 0; i < chordTypes.length; i++) {
        const type = chordTypes[i];
        if (settings[type]) {
          harmonyState = addAlternateChord({
            chordType: type,
            harmonyState,
            settings: newSettings,
          });
        } else {
          let typeArr: AlternateChordType[];
          if (type == "minorTwoFive") {
            typeArr = ["TwoInMinor25", "FiveInMinor25"];
          } else {
            typeArr = [type];
          }
          harmonyState = removeAlternateChords({
            chordsToRemove: typeArr,
            harmonyState,
            settings: newSettings,
          });
        }
      }

      return harmonyState;
    },
    [settings]
  );

  const clearAllNotes = useCallback(() => {
    // console.log(harmonyState.harmonyNotes);
    harmonyState.harmonyNotes.forEach((note) => {
      // padSynth.triggerRelease(note.note, Tone.now());
    });
    padSynth.releaseAll(Tone.now());

    harmonyState.bassNotes.forEach((note) => {
      // bassSynth.triggerRelease(note.note, Tone.now());
    });
    bassSynth.releaseAll(Tone.now());

    // drumsSampler.triggerRelease(DRUM_NOTE);
    drumsSampler.releaseAll(Tone.now());
    setActiveHarmony({
      ...activeHarmonyRef.current,
      activeHarmonyNotes: [],
    });
  }, [
    harmonyState.harmonyNotes,
    harmonyState.bassNotes,
    activeHarmonyRef,
    setActiveHarmony,
  ]);

  const clearBadLeadNotes = useCallback(
    ({
      scaleNotes,
      scaleNoteMap,
      lastScaleNoteMap,
    }: {
      scaleNotes: NoteName[];
      scaleNoteMap: ScaleNoteMap;
      lastScaleNoteMap: ScaleNoteMap;
    }) => {
      const removePressedNotes: NoteWithOctave[] = [];
      const newNotes: NoteWithOctave[] = [];
      pressedNotesRef.current.forEach((note) => {
        const noteName = note.name;
        if (
          !scaleNotes.includes(noteName) ||
          noteHasDifferentTriggerKey({
            note,
            prev: lastScaleNoteMap,
            curr: scaleNoteMap,
          })
        ) {
          removePressedNotes.push(note);
          const triggerKey = getTriggerKey({
            note,
            obj: lastScaleNoteMap,
          });

          if (triggerKey == null) {
            throw new Error("null trigger key");
          }
          newNotes.push(scaleNoteMap[triggerKey]);
        }
      });

      removePressedNotes.forEach((note) => {
        // leadSynth.releaseAll("+0");
        leadSynth.triggerRelease(noteOctaveStr(note), "+0");
      });
      newNotes.forEach((note) => {
        leadSynth.triggerAttack(noteOctaveStr(note), "+0", 1);
      });

      setPressedNotes(
        [...pressedNotesRef.current, ...newNotes].filter(
          (note) => !removePressedNotes.includes(note)
        )
      );
    },
    [pressedNotesRef, setPressedNotes]
  );

  const [loading, setLoading] = useState(!OFFLINE_MODE);
  const [volume, setVolume] = useState(50);

  const handleKeyPress = useCallback(
    ({ noteWithOctave }: { noteWithOctave: NoteWithOctave }) => {
      // console.log(noteWithOctave);

      if (
        !pressedNotesRef.current.find(
          (note) =>
            note.name === noteWithOctave.name &&
            note.octave === noteWithOctave.octave
        )
      ) {
        // console.log(pressedNotesRef.current, noteWithOctave);
        logEvent({
          eventName: "note pressed",
          details: {
            note: noteOctaveStr(noteWithOctave),
          },
        });

        // console.log("handle key press");

        leadSynth.triggerAttack(noteOctaveStr(noteWithOctave), "+0", 1);

        setPressedNotes([...pressedNotesRef.current, noteWithOctave]);
      }
    },
    [logEvent, pressedNotesRef, setPressedNotes]
  );

  const handleKeyRelease = useCallback(
    ({ noteWithOctave }: { noteWithOctave: NoteWithOctave }) => {
      leadSynth.triggerRelease(noteOctaveStr(noteWithOctave));

      setPressedNotes(
        pressedNotesRef.current.filter(
          (prev) => !sameNoteWithOctave({ note1: prev, note2: noteWithOctave })
        )
      );
    },
    [pressedNotesRef, setPressedNotes]
  );

  const handleKeyClick = useCallback(
    ({ key }: { key: string }) => {
      handleKeyPress({
        noteWithOctave: noteWithOctaveFromStr(
          KEY_NUMBER_TO_NOTE_MAP[KEY_NAME_TO_NUMBER_MAP[key]]
        ),
      });
    },
    [handleKeyPress]
  );

  const handleKeyClickRelease = useCallback(
    ({ key }: { key: string }) => {
      handleKeyRelease({
        noteWithOctave: noteWithOctaveFromStr(
          KEY_NUMBER_TO_NOTE_MAP[KEY_NAME_TO_NUMBER_MAP[key]]
        ),
      });
    },
    [handleKeyRelease]
  );

  const createScaleNoteMap = useCallback(
    ({ scaleNotes, offset }: { scaleNotes: NoteName[]; offset: number }) => {
      const scaleNoteMap: ScaleNoteMap = {};
      const forMidi: MidiScaleNoteMap = {};

      const enharmonicFlatScaleNotes = scaleNotes.map((n) =>
        getEnharmonicFlat({ note: n })
      );

      const firstNoteAboveC = getFirstNoteAboveC({
        notes: enharmonicFlatScaleNotes,
      });

      const octavearr = ["3", "4", "5", "6"];
      let octave = STARTING_OCTAVE - 1;

      for (let i = 0; i < NOTE_ARR.length * octavearr.length; i++) {
        const idx = (i + offset) % enharmonicFlatScaleNotes.length;
        let noteval = enharmonicFlatScaleNotes[idx];

        if (noteval === firstNoteAboveC) {
          octave++;
        }

        if (i < KEY_NOTES_A_Z.length) {
          scaleNoteMap[KEY_NOTES_A_Z[i]] = { name: noteval, octave };
        }

        forMidi[NOTE_ARR[i % 12] + octavearr[Math.floor(i / 12)]] = {
          name: noteval,
          octave: octave - 1,
        };
      }

      return { scaleNoteMap: scaleNoteMap, scaleNoteMapForMIDIInput: forMidi };
    },
    []
  );

  const applyChordAtQ = useCallback(
    ({
      q,
      time,
      harmonyState,
    }: {
      q: number;
      time?: Tone.Unit.Time;
      harmonyState: HarmonyState;
    }) => {
      // console.log(harmonyState, q, q / 4, q % 4);
      const chord = harmonyState.chords.find(
        (chord) =>
          chord.measureNumber === Math.floor(q / 4) &&
          chord.beatNumber === q % 4
      );

      if (chord == null) {
        window.alert("No chord found at this time");

        return;
      }

      setLoopState((prev) => ({
        ...prev,
        chordOnIdx: harmonyState.chords.indexOf(chord),
      }));

      const activeHarmonyNotes: Note[] = [];

      bassSynth.releaseAll(time);

      // trigger notes
      harmonyState.bassNotes.forEach((bassNote) => {
        if (
          chord.measureNumber == bassNote.measureNumber &&
          chord.beatNumber == bassNote.deltaFromMeasure
        ) {
          bassSynth.triggerAttack(
            bassNote.note,
            // LENGTH_MAP[bassNote.length],
            time,
            bassNote.velocity / 100
          ); // divide by 100 because of library weirdness
          activeHarmonyNotes.push(bassNote);
        }
      });

      padSynth.releaseAll(time);

      harmonyState.harmonyNotes.forEach((harmonyNote) => {
        if (
          chord.measureNumber == harmonyNote.measureNumber &&
          chord.beatNumber == harmonyNote.deltaFromMeasure
        ) {
          padSynth.triggerAttack(
            harmonyNote.note,
            // LENGTH_MAP[harmonyNote.length],
            time,
            harmonyNote.velocity / 100
          ); // divide by 100 because of library weirdness
          activeHarmonyNotes.push(harmonyNote);
        }
      });

      // set key mapping
      const scaleNotes = trimToSix({ chord });
      const offset = getOffset({ scaleNotes });

      const lastScaleNoteMap = JSON.parse(
        JSON.stringify(activeHarmonyRef.current.scaleNoteMap)
      );
      const lastScaleNoteMapForMIDIInput = JSON.parse(
        JSON.stringify(activeHarmonyRef.current.scaleNoteMapForMIDIInput)
      );
      const { scaleNoteMap, scaleNoteMapForMIDIInput } = createScaleNoteMap({
        scaleNotes,
        offset,
      });

      setActiveHarmony({
        ...activeHarmonyRef.current,
        scaleNotes,
        activeHarmonyNotes,
        lastScaleNoteMap,
        lastScaleNoteMapForMIDIInput,
        scaleNoteMap,
        scaleNoteMapForMIDIInput,
      });
      clearBadLeadNotes({
        scaleNotes,
        scaleNoteMap,
        lastScaleNoteMap,
      });
      // set scale notes ?
    },
    [clearBadLeadNotes, createScaleNoteMap, activeHarmonyRef, setActiveHarmony]
  );

  const playDrums = useCallback(({ time }: { time: Tone.Unit.Time }) => {
    drumsSampler.releaseAll(time);
    drumsSampler.triggerAttack(DRUM_NOTE, time);
  }, []);

  const [drumQs, setDrumQs] = useState(initDrumQs);

  const { startTimeLoop, endTimeLoop } = useTimeLoop({
    harmonyNotes: harmonyState.harmonyNotes,
    playDrums,
    applyChordAtQ,
    barsInLoop: numberOfBarsInt,
    harmonyState,
    drumQs,
  });

  const playLoop = useCallback(() => {
    setLoopState((prev) => ({ ...prev, isPlaying: true }));

    startTimeLoop();
  }, [startTimeLoop]);

  const stopLoop = useCallback(() => {
    setLoopState((prev) => ({
      ...prev,
      chordOnIdx: -1,
      isPlaying: false,
    }));

    clearAllNotes();
    endTimeLoop();
  }, [clearAllNotes, endTimeLoop]);

  const handleSpacePress = useCallback(() => {
    logEvent({
      eventName: "space pause",
    });

    if (!loading && harmonyState.chords.length > 0) {
      if (loopState.isPlaying) {
        stopLoop();
      } else {
        stopLoop();
        playLoop();
      }
    }
  }, [
    stopLoop,
    playLoop,
    loading,
    harmonyState.chords.length,
    loopState.isPlaying,
    logEvent,
  ]);

  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      // console.log("on key down");
      if (event.keyCode === 32) {
        event.preventDefault();
        handleSpacePress();
        return;
      }

      if (!activeHarmonyRef.current.scaleNoteMap[event.keyCode]) {
        return;
      } else {
        const note = activeHarmonyRef.current.scaleNoteMap[event.keyCode];
        // console.log("on key down");
        handleKeyPress({ noteWithOctave: note });
      }
    };

    const onKeyUp = (event: KeyboardEvent) => {
      if (event.keyCode === 32) {
        return;
      }

      if (!activeHarmonyRef.current.scaleNoteMap[event.keyCode]) {
        return;
      } else {
        const note = activeHarmonyRef.current.scaleNoteMap[event.keyCode];
        handleKeyRelease({ noteWithOctave: note });
      }
    };

    document.addEventListener("keydown", onKeyDown, false);
    document.addEventListener("keyup", onKeyUp, false);

    return () => {
      document.removeEventListener("keydown", onKeyDown, false);
      document.removeEventListener("keyup", onKeyUp, false);
    };
  }, [handleKeyPress, handleKeyRelease, handleSpacePress, activeHarmonyRef]);

  const setAllVolumes = useCallback(({ newVolume }: { newVolume: number }) => {
    if (newVolume === 0) {
      padSynth.volume.value = -1000;
      bassSynth.volume.value = -1000;
      leadSynth.volume.value = -1000;
      drumsSampler.volume.value = -1000;
      return;
    }

    padSynth.volume.value = -22 + newVolume / 15;
    bassSynth.volume.value = -20 + newVolume / 15;
    leadSynth.volume.value = -12 + newVolume / 15;
    drumsSampler.volume.value = -20 + newVolume / 15;
  }, []);

  const handleNewPadSynth = useCallback(() => {
    stopLoop();

    logEvent({
      eventName: "new pad synth",
    });

    padSynth.dispose();

    padSynth = getNewPadSynth();
    setAllVolumes({ newVolume: volume });
  }, [stopLoop, logEvent, setAllVolumes, volume]);

  const handleNewDrums = useCallback(() => {
    stopLoop();

    logEvent({
      eventName: "new drums",
    });

    drumsSampler.dispose();

    const newDrumsResult = getNewDrums();

    drumsSampler = newDrumsResult.synth;
    setDrumQs(newDrumsResult.drumQs);
    setAllVolumes({ newVolume: volume });
  }, [stopLoop, logEvent, setAllVolumes, volume]);

  const [chordsMidiFilePath, setChordsMidiFilePath] = useState("");

  const prepareUserProvidedChordsForServer = useCallback(() => {
    const uicStrArr: string[] = [];
    userInputChords.forEach((uic: UserInputChord) => {
      if (uic.active) {
        const uicStr =
          uic.root + "," + uic.quality + "," + uic.measureNumber.toString();
        uicStrArr.push(uicStr);
      }
    });
    return uicStrArr.join(";");
  }, [userInputChords]);

  const prepareSettingsForServer = useCallback((): Record<string, string> => {
    return {
      numberOfBars: settings.numberOfBars,
      voicingComplexity: settings.voicingComplexity.toString(),
      closedOrOpen: settings.closedOrOpen,
      passingChordFrequency: settings.passingChordFrequency.toString(),
      majorTwoFive: settings.majorTwoFive.toString(),
      minorTwoFive: settings.minorTwoFive.toString(),
      diminished: settings.diminished.toString(),
      modalInterchange: settings.modalInterchange.toString(),
      primaryDominant: settings.primaryDominant.toString(),
      secondaryDominant: settings.secondaryDominant.toString(),
      substituteDominant: settings.substituteDominant.toString(),
    };
  }, [settings]);

  const rawChordsBassAndHarmonyNotes = useCallback(
    (responseData: { chords: Chord[] }) => {
      const harmonyNotes = [];

      for (let i = 0; i < responseData.chords.length; i++) {
        const chord = responseData.chords[i];
        for (let i2 = 0; i2 < chord.harmonyNotes.length; i2++) {
          const note = chord.harmonyNotes[i2];
          if (
            note.complexity == settings.voicingComplexity &&
            note.closedOrOpen == settings.closedOrOpen
          ) {
            harmonyNotes.push(...note.notes);
          }
        }
      }

      return {
        harmonyNotes,
        chords: responseData.chords,
        bassNotes: responseData.chords.map((chord: Chord) => chord.bassNote),
      };
    },
    [settings.voicingComplexity, settings.closedOrOpen]
  );

  const handleNewJSON = useCallback(
    ({ responseData }: { responseData: any }) => {
      const scaleNotes = trimToSix({ chord: responseData.chords[0] });
      const offset = getOffset({ scaleNotes });

      const lastScaleNoteMap = JSON.parse(
        JSON.stringify(activeHarmonyRef.current.scaleNoteMap)
      );
      const lastScaleNoteMapForMIDIInput = JSON.parse(
        JSON.stringify(activeHarmonyRef.current.scaleNoteMapForMIDIInput)
      );
      const { scaleNoteMap, scaleNoteMapForMIDIInput } = createScaleNoteMap({
        scaleNotes,
        offset,
      });

      setActiveHarmony({
        ...activeHarmonyRef.current,
        activeHarmonyNotes: [],
        scaleNotes,
        lastScaleNoteMap,
        lastScaleNoteMapForMIDIInput,
        scaleNoteMap,
        scaleNoteMapForMIDIInput,
      });
      setHarmonyState(
        applySettingsToHarmonyChords({
          harmonyState: {
            ...harmonyState,
            baseChords: responseData.chords,
            dependentChords: {
              // majorTwoFive: responseData.majorTwoFives,
              minorTwoFive: responseData.minorTwoFives,
              secondaryDominant: responseData.secondaryDominants,
              // substituteDominant: responseData.substituteDominants,
              modalInterchange: responseData.modalInterchange,
            },
            key: responseData.key,
            ...rawChordsBassAndHarmonyNotes(responseData),
          },
          newSettings: settings,
        })
      );

      // console.log(responseData);

      setSettings((prev) => ({
        ...prev,
        numberOfBars: responseData.numberOfBars,
      }));

      clearBadLeadNotes({
        scaleNoteMap,
        lastScaleNoteMap,
        scaleNotes,
      });
    },
    [
      clearBadLeadNotes,
      applySettingsToHarmonyChords,
      createScaleNoteMap,
      harmonyState,
      rawChordsBassAndHarmonyNotes,
      setHarmonyState,
      settings,
      activeHarmonyRef,
      setActiveHarmony,
    ]
  );

  const fetchNewLoop = useCallback(() => {
    if (OFFLINE_MODE) {
      handleNewJSON({ responseData: SampleJSON });
      return;
    }

    setLoading(true);
    let URLstring =
      "https://irix6aarbbbyyl5bjgw6im5h7a0plnag.lambda-url.us-east-2.on.aws/Prod/compose?" +
      new URLSearchParams({
        ...prepareSettingsForServer(),
        userProvidedChords: prepareUserProvidedChordsForServer(),
      });
    let requestCount = 0;
    fetch(URLstring)
      .then((response) => response.json())
      .then((responseData) => {
        if (!!responseData.chords) {
          requestCount = 0;
          setLoading(false);

          handleNewJSON({ responseData });
        } else {
          requestCount++;
          if (requestCount > 9) {
            alert(
              "This is embarrassing... Something went wrong. Please try again :D"
            );
            setLoading(false);
          } else {
            fetchNewLoop();
          }
        }
      });
  }, [
    prepareSettingsForServer,
    prepareUserProvidedChordsForServer,
    handleNewJSON,
  ]);

  const isToneLoadedRef = useRef(false);

  useEffect(() => {
    // json request for initial chords here

    Tone.loaded().then(() => {
      if (!isToneLoadedRef.current) {
        isToneLoadedRef.current = true;
        fetchNewLoop();
        setAllVolumes({ newVolume: volume });
      }
    });
  }, [fetchNewLoop, setAllVolumes, volume]);

  const updateVoicings = useCallback(
    ({
      voicingComplexity,
      closedOrOpen,
      harmonyState,
    }: {
      voicingComplexity: number;
      closedOrOpen: ClosedOrOpen;
      harmonyState: HarmonyState;
    }) => {
      const harmonyNotes: HarmonyNote[] = [];

      for (let i = 0; i < harmonyState.chords.length; i++) {
        const chord = harmonyState.chords[i];
        for (let i2 = 0; i2 < chord.harmonyNotes.length; i2++) {
          const note = chord.harmonyNotes[i2];
          if (
            note.complexity == voicingComplexity &&
            note.closedOrOpen == closedOrOpen
          ) {
            harmonyNotes.push(...note.notes);
          }
        }
      }

      harmonyState.harmonyNotes = harmonyNotes;
      return harmonyState;
    },
    []
  );

  const onChordSymbolClicked = useCallback(
    ({ chord, idx }: { chord: Chord; idx: number }) => {
      logEvent({
        eventName: "chord symbol clicked",
      });

      stopLoop();

      if (loopState.chordOnIdx != idx) {
        applyChordAtQ({
          q: chord.measureNumber * 4 + chord.beatNumber,
          harmonyState,
        });
      } else {
        stopLoop();
      }
    },
    [logEvent, applyChordAtQ, stopLoop, loopState.chordOnIdx, harmonyState]
  );

  const [isModalOpen, setIsModalOpen] = useState(getHasSeenNux() == null);

  const onNewMediumLevelComplexity = useCallback(
    ({ complexity }: { complexity: number }) => {
      const newSettings = randomizeLowLevelSettings({ complexity, settings });
      setSettings(newSettings);

      if (harmonyState.chords.length > 0) {
        const harmonyWithNewChords = applySettingsToHarmonyChords({
          harmonyState,
          newSettings,
        });
        const harmonyWithNewChordsAndVoicings = updateVoicings({
          voicingComplexity: newSettings.voicingComplexity,
          closedOrOpen: newSettings.closedOrOpen,
          harmonyState: harmonyWithNewChords,
        });
        setHarmonyState(harmonyWithNewChordsAndVoicings);
      }
    },
    [
      harmonyState,
      applySettingsToHarmonyChords,
      setHarmonyState,
      updateVoicings,
      settings,
    ]
  );

  return (
    <>
      <NuxModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />

      <Header onHelpClicked={() => setIsModalOpen(true)} />

      <div
        className="App"
        style={{ gap: 48, backgroundColor: BACKGROUND_COLOR }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 48,
          }}
          className="column-when-small"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 48,
            }}
          >
            {leadSynth != null && (
              <LeadStuff
                activeHarmony={activeHarmonyState}
                leadSynth={leadSynth}
                handleKeyClick={handleKeyClick}
                handleKeyClickRelease={handleKeyClickRelease}
                pressedNotes={pressedNotesState}
                logEvent={logEvent}
                handleKeyPress={handleKeyPress}
                handleKeyRelease={handleKeyRelease}
              />
            )}

            <ChordStuff
              harmonyState={harmonyState}
              onChordSymbolClicked={onChordSymbolClicked}
              loopState={loopState}
              logEvent={logEvent}
              loading={loading}
              chordsMidiFilePath={chordsMidiFilePath}
              stopLoop={stopLoop}
              playLoop={playLoop}
              settings={settings}
              setSettings={setSettings}
              fetchNewLoop={fetchNewLoop}
              userInputChords={userInputChords}
              setUserInputChords={setUserInputChords}
              handleNewPadSynth={handleNewPadSynth}
              onNewMediumLevelComplexity={onNewMediumLevelComplexity}
            />
          </div>

          <div
            className="shadow box"
            style={{
              fontSize: 14,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-around",
              paddingTop: 16,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 16,
                alignItems: "center",
              }}
            >
              <PlayPauseButton
                isPlaying={loopState.isPlaying}
                playLoop={playLoop}
                stopLoop={stopLoop}
                loading={loading}
                logEvent={logEvent}
              />

              <DrumButton onClick={handleNewDrums} />
            </div>

            <VolumeSetting
              onVolumeChange={(value) => {
                setVolume(value);
                setAllVolumes({ newVolume: value });
              }}
              volume={volume}
            />
          </div>
        </div>
      </div>
    </>
  );
}

type Notification = {
  text: string;
  id: string;
};

function DrumButton({ onClick }: { onClick: () => void }) {
  const [notifications, setNotifications] = useState<Notification[]>([]);

  useEffect(() => {
    // console.log(notifications.length);
  }, [notifications.length]);

  const addNotification = () => {
    const id = Date.now().toString();

    setNotifications((prev) => [...prev, { id, text: `new drums` }]);

    // Remove the notification after the animation ends
    setTimeout(() => {
      setNotifications((prev) => {
        return prev.filter((n) => n.id !== id);
      });
    }, 2000); // Matches the animation duration
  };

  return (
    <div
      className="icon clickable"
      onClick={() => {
        addNotification();
        onClick();
      }}
    >
      <LuDrum size={72} color={PRIMARY_COLOR} />
      <div className="notification-container">
        {notifications.map((n) => (
          <div
            key={n.id}
            className="no-highlight notification"
            style={{ color: ACTIVE_COLOR, cursor: "pointer" }}
          >
            {n.text}
          </div>
        ))}
      </div>
    </div>
  );
}
